import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import useVisibilitySettings from '../../../utils/useVisibilitySettings';

const BoxRowWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 90rem;
  width: 100%;
  margin: auto;
  ${theme.below.xl} {
    padding: 0 1rem;
  }
  ${theme.below.lg} {
    flex-wrap: wrap !important;
  }

  ${theme.below.md} {
    &.boxes-4 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
`;

const BoxWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  aspect-ratio: ${props => props?.aspect.replace(':', ' / ') ?? '1 / 1'};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  position: relative;
  width: 100%;
  margin-right: 1rem;
  text-align: center;
  a {
    color: ${props => props.textColor};
    text-decoration: none;
  }

  .no-link {
    pointer-events: none;
  }

  .wideLink{
    width: 100%;
    height: 100%;
  }

  ${theme.below.lg} {
    margin: 0 0 1rem 0;
  }
    .numItems-4 & {
      ${theme.below.lg} {
        margin: 0 0 1rem 0;
        &.under {
          margin: 0;
          padding: 0;
          & > div {
            margin-top: 0;
            h2 {
              display: none;
            }
            p {
              margin: 0;
            }
            a {
              font-size: 14px;
              height: auto;
              line-height: 14px;
              padding-top: 10px;
              padding-bottom: 6px;
              svg {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  

  ${theme.below.md} {
    aspect-ratio: ${props => props?.aspectMob.replace(':', ' / ') ?? '1 / 1'};
  }

  &:last-child  {
    margin-right: 0;
  }
`;
const BoxContent = styled('div')`
  padding: 2rem;
  h2 {
    font-size: ${props => props.titleFontSizes.full};
    line-height: 1.4;
    margin-bottom: 5px;
    font-weight: bold;
    ${theme.below.xl} {
      font-size: ${props => props.titleFontSizes.xl};
    }
    ${theme.below.lg} {
      font-size: ${props => props.titleFontSizes.lg};
    }
    ${theme.below.md} {
      font-size: ${props => props.titleFontSizes.md};
    }
    ${theme.below.sm} {
      font-size: ${props => props.titleFontSizes.sm};
    }
  }
  h3 {
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    margin: 20px 0;
  }

  ${theme.below.lg} {
    bottom: 30px;
    &.under {
      width: 100%;
      max-width: 480px;
      margin-top: 10px;
      bottom: auto;
      padding: 0 0.5rem;

      h2 {
        font-size: 16px;
        margin-bottom: 0.5em;
      }

      p {
        font-size: 14px;
      }
    }
  }
  ${theme.below.md} {
    p{
      font-size: 14px;
    }
  }
`;

 export const BoxButton = styled('span')`
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  height: 53px;
  line-height: 51px;
  display: inline-flex;
  transition: all, 0.2s ease;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0 1rem;
  background: transparent;
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  font-size: 20px;

  &.primary {
    background: ${theme.colors.accent};
    border: 1px solid ${theme.colors.accent};
    &:hover {
      background: ${theme.colors.lightgreen};
      color: white;
      border-color: ${theme.colors.lightgreen};
    }
    &.ghost {
      background: transparent;
      border: 1px solid white;
      &:hover {
        background: ${theme.colors.accent};
        color: white;
        border-color: ${theme.colors.accent};
      }
    }
  }
  &.blackweek {
      background: ${theme.colors.black};
      color: white;
      border-color: ${theme.colors.black};
      &:hover {
        background: ${theme.colors.darkgrey};
        color: white;
        border-color: ${theme.colors.darkgrey};
      }
    }
  &.christmas-red {
    background: ${theme.colors.christmasred};
    color: white;
    border-color: ${theme.colors.christmasred};
    &:hover {
      background: ${theme.colors.red};
      color: white;
      border-color: ${theme.colors.red};
    }
  }

  svg {
    max-width: 1.5rem;
    max-height: 1rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  ${theme.below.xl} {
    padding: 0 1rem;
  }
  ${theme.below.lg} {
    font-size: 20px;
    padding: 10px;
    height: 43px;
    line-height: 1.4;
    width: 85%;
    height: fit-content;
  }
  ${theme.below.sm} {
    font-size: 1rem;
    display: inline-block;
    width: auto;
    padding: 0.5rem 2rem;
  }
`;

export const TargetedLink = ({ to, children, ...rest }) => {
  const externalLink = to?.includes('http');
  const isLink = to?.length > 0; // use for disable click when empty
  if (externalLink) {
    return (
      <a
        {...rest}
        href={to}
        data-external={externalLink}
        target="_blank"
        rel="noreferrer"
        className={'wideLink ' + (isLink ? '' : 'no-link')}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      {...rest}
      data-external={externalLink}
      className={'wideLink ' + (isLink ? '' : 'no-link')}
    >
      {children}
    </Link>
  );
};

const ColorBoxComponent = ({
  preTitle,
  title,
  titleSizeDesktop,
  titleSizeMobile,
  subTitle,
  text,
  textColor,
  color,
  aspect,
  aspectMob,
  linkUrl,
  buttonText,
  buttonTheme,
  ...rest
}) => {
  const smallSizes = {
    full: '24px',
    xl: '24px',
    lg: '24px',
    md: '20px',
    sm: '16px'
  };
  const regularSizes = {
      full: '40px',
      xl: '36px',
      lg: '30px',
      md: '24px',
      sm: '20px'
    };
  const largeSizes = {
      full: '48px',
      xl: '44px',
      lg: '38px',
      md: '34px',
      sm: '30px'
    };
  const xtraLargeSizes = {
      full: '56px', 
      xl: '52px',
      lg: '46px',
      md: '42px',
      sm: '38px'
    };
    const titleFontSizesDesktop = titleSizeDesktop === 'X-LARGE' 
      ? xtraLargeSizes 
      : titleSizeDesktop === 'LARGE' 
      ? largeSizes 
      : titleSizeDesktop === 'SMALL'
      ? smallSizes 
      : regularSizes;

      const titleFontSizesMobile = titleSizeMobile === 'X-LARGE' 
      ? xtraLargeSizes 
      : titleSizeMobile === 'LARGE' 
      ? largeSizes 
      : titleSizeMobile === 'SMALL'
      ? smallSizes 
      : regularSizes;

  return (
    <BoxWrapper
      aspect={aspect}
      aspectMob={aspectMob}
      backgroundColor={color}
      textColor={textColor}
      {...rest}
    >
     <Above breakpoint="lg">
     {matches => (
      <BoxContent titleFontSizes={matches ? titleFontSizesDesktop : titleFontSizesMobile}>
        <TargetedLink to={linkUrl} style={{ textDecoration: 'none' }}>
          {preTitle && (<h3>{preTitle}</h3>)}
          {title && (<h2>{title}</h2>)}
          {subTitle && (<h3>{subTitle}</h3>)}
          {text && (<p>{text}</p>)}
        </TargetedLink>
        {linkUrl && buttonText && (
          <TargetedLink to={linkUrl} style={{ textDecoration: 'none' }}>
            <BoxButton className={buttonTheme}>
              {buttonText}
            </BoxButton>
          </TargetedLink>
        )}
      </BoxContent>
      )}
    </Above>
    </BoxWrapper>
  );
};

const ColorBox = ({
  title,
  titleSizeDesktop,
  titleSizeMobile,
  subTitle,
  preTitle,
  text,
  textColor,
  color,
  aspect,
  aspectMob,
  linkUrl,
  buttonText,
  buttonTheme,
  sweden,
  denmark,
  norway,
  finland,
  netherlands,
  eu,
  loggedIn,
  loggedOut,
  ...rest
}) => {
  const visibilitySettings = {
    sweden: sweden?.value,
    denmark: denmark?.value,
    norway: norway?.value,
    finland: finland?.value,
    netherlands: netherlands?.value,
    eu: eu?.value,
    loggedIn: loggedIn?.value,
    loggedOut: loggedOut?.value
  };
  const doNotRender = useVisibilitySettings(visibilitySettings);
  return (
    <>
      { doNotRender ? null : (
          <ColorBoxComponent
            preTitle={preTitle?.value}
            title={title?.value}
            titleSizeDesktop={titleSizeDesktop?.value}
            titleSizeMobile={titleSizeMobile?.value}
            subTitle={subTitle?.value}
            text={text?.value}
            textColor={textColor?.value}
            color={color?.value}
            aspect={aspect?.value}
            aspectMob={aspectMob?.value}
            linkUrl={linkUrl?.value}
            buttonText={buttonText?.value}
            buttonTheme={buttonTheme?.value}
            {...rest}
          />
      )}
    </>
  )};

export default ColorBox;

export const ColorBoxRow = ({ children }) => {
  return (
    <BoxRowWrapper
      className={cx('contentComponent', `boxes-${children.length}`)}
    >
      {children}
    </BoxRowWrapper>
  );
};
